"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var navbar_animation_1 = require("./navbar-animation");
var scrollManager_1 = require("./scrollManager");
require("./cursor_cloud");
require("./blur-animations");
setUp();
function setUp() {
    var scrollTracker = new scrollManager_1.ScrollTracker();
    (0, navbar_animation_1.setUpNavAnimation)(scrollTracker);
}
