"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var model_manager_1 = require("./model-manager");
var startup_animations_1 = require("./startup-animations");
require("src/graphics/default_animations");
require("./index.scss");
setup();
function setup() {
    (0, startup_animations_1.setUpStartAnimations)();
    (0, model_manager_1.start)();
}
