"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var THREE = __importStar(require("three"));
var CursorCloud = /** @class */ (function () {
    function CursorCloud(scene) {
        this.driftSpeeds = [];
        this.maxSpeed = 0.0008;
        this._isVisible = false;
        this.hasTouched = false;
        this.tx = 0;
        this.ty = 0;
        this.points = [];
        this.POINTS = 100;
        this.radius = 0.02;
        this.createVertices();
        var material = new THREE.PointsMaterial({ color: 0xffffff, size: 1, sizeAttenuation: false, blending: THREE.AdditiveBlending });
        this.geometry = new THREE.BufferGeometry();
        this.initControls();
        this.pointsMesh = new THREE.Points(this.geometry, material);
        this.pointsMesh.visible = false;
        scene.add(this.pointsMesh);
    }
    CursorCloud.prototype.initControls = function () {
        var _this = this;
        window.addEventListener('mousemove', function (e) {
            if (_this.hasTouched)
                return;
            _this.isVisible = true;
            _this.tx = ((e.clientX) / window.innerWidth) * 2 - 1;
            _this.ty = -((e.clientY) / window.innerHeight) * 2 + 1;
        });
        document.addEventListener('mouseleave', function () { _this.isVisible = false; });
        window.addEventListener('touchstart', function (e) {
            _this.hasTouched = true;
        });
    };
    Object.defineProperty(CursorCloud.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        set: function (val) {
            this.pointsMesh.visible = val;
            this._isVisible = val;
        },
        enumerable: false,
        configurable: true
    });
    CursorCloud.prototype.udpate = function () {
        this.updateForDrift();
    };
    CursorCloud.prototype.updateForDrift = function () {
        for (var i = 0; i < this.POINTS; i++) {
            var v = this.points[i];
            v.add(this.driftSpeeds[i]);
            if (v.length() > this.radius) {
                this.driftSpeeds[i].negate();
            }
        }
        this.updateGeometry();
    };
    CursorCloud.prototype.updateGeometry = function () {
        var arr = [];
        for (var i = 0; i < this.POINTS; i++) {
            arr.push(this.points[i].x + this.tx, this.points[i].y + this.ty * window.innerHeight / window.innerWidth, this.points[i].z);
        }
        this.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(arr), 3));
        this.geometry.attributes.position.needsUpdate = true;
    };
    CursorCloud.prototype.createVertices = function () {
        var i = 0;
        while (i < this.POINTS) {
            var x = Math.random() * this.radius * 2 - this.radius;
            var y = Math.random() * this.radius * 2 - this.radius;
            if (Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) < this.radius) { // only use points inside the unit sphere
                this.driftSpeeds[i] = this.randomVelocity();
                this.points[i] = new THREE.Vector3(x, y, 0);
                i++;
            }
        }
    };
    CursorCloud.prototype.randomVelocity = function () {
        var dx = Math.random() * 2 * this.maxSpeed - this.maxSpeed;
        var dy = Math.random() * 2 * this.maxSpeed - this.maxSpeed;
        return new THREE.Vector3(dx, dy, 0);
    };
    return CursorCloud;
}());
var renderer = new THREE.WebGLRenderer({ canvas: document.getElementById('cloud-canvas'), alpha: true });
var ratio = setUpRenderer(renderer);
var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(75, ratio, 0.1, 1000);
camera.position.z = (1 / ratio) * (31 / 24);
var cursor_cloud = new CursorCloud(scene);
animate();
function animate() {
    cursor_cloud.udpate();
    renderer.clear();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
}
function onResize() {
    var ratio = setUpRenderer(renderer);
    cursor_cloud.udpate();
    camera.aspect = ratio;
    camera.position.z = (1 / ratio) * (31 / 24);
    camera.updateProjectionMatrix();
}
window.addEventListener('resize', onResize, false);
function setUpRenderer(renderer) {
    renderer.autoClear = false;
    var width = window.innerWidth;
    renderer.setSize(width, window.innerHeight);
    return width / window.innerHeight;
}
