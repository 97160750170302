"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUpStartAnimations = void 0;
var scrollManager_1 = require("src/graphics/scrollManager");
var typewriter_1 = require("src/graphics/typewriter");
var scrollTracker = new scrollManager_1.ScrollTracker();
function setUpStartAnimations() {
    window.onload = startTyping;
    var buttonElements = document.querySelectorAll('.continue-scroll');
    buttonElements.forEach(function (button) {
        var btn = button;
        btn.onclick = pageScroll;
    });
}
exports.setUpStartAnimations = setUpStartAnimations;
var delay = 200;
function startTyping() {
    var title = document.getElementById('title');
    var titleText = 'Meet Me...';
    var paragraph = document.getElementById('paragraph');
    var paragraphText = '- 21 year old from Scotland🏴󠁧󠁢󠁳󠁣󠁴󠁿 \\\\- 4th year CompSci & Maths at the University of Edinburgh & EPFL\\\\- Native English🇬🇧 and French🇫🇷 speaker';
    var paraTyper = new typewriter_1.TypeWriter(paragraph, paragraphText, 30, delay, 10, true, function () { return setTimeout(makeButtonVisible, delay); });
    var titleTyper = new typewriter_1.TypeWriter(title, titleText, 50, 0, 0, false, paraTyper.start.bind(paraTyper));
    titleTyper.start();
}
function makeButtonVisible() {
    var button = document.getElementById('continue-scroll-nav');
    button.style.visibility = 'visible';
    button.style.opacity = '1';
    button.addEventListener('click', pageScroll);
}
function pageScroll() {
    // Added 10 pixels as blurring in does not occur if the page is only just not loaded.
    scrollTracker.scrollBy(window.innerHeight + 10);
}
