"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUpNavAnimation = void 0;
function setUpNavAnimation(scrollTracker) {
    window.onscroll = function () { return onScroll(scrollTracker); };
}
exports.setUpNavAnimation = setUpNavAnimation;
var navBarScrollTolerance = 1;
function onScroll(scrollTracker) {
    var link = document.getElementById('bigger-text');
    if (scrollTracker.currentScroll > navBarScrollTolerance)
        link.style.fontSize = '24px';
    else
        link.style.fontSize = '';
}
