"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeWriter = void 0;
var TypeWriter = /** @class */ (function () {
    function TypeWriter(element, text, speed, delay, newLineDelay, cursorsRemainVisible, callback) {
        this.element = element;
        this.text = text;
        this.speed = speed;
        this.delay = delay;
        this.newLineDelay = newLineDelay;
        this.cursorsRemainVisible = cursorsRemainVisible;
        this.callback = callback;
        this.index = 0;
        this.started = false;
        this.finished = false;
        this.blinkRate = 400;
        this.cursorsIsVisible = true;
    }
    TypeWriter.prototype.start = function () {
        this.blinkInterval = setInterval(this.cursorBlink.bind(this), this.blinkRate);
        this.started = true;
        setTimeout(this.typeWrite.bind(this), this.delay);
    };
    TypeWriter.prototype.cursorBlink = function () {
        if (this.cursorsIsVisible) {
            this.cursorsIsVisible = false;
            this.element.innerHTML = this.element.innerHTML.slice(0, -1);
            this.element.innerHTML += ' ';
            if (this.finished && !this.cursorsRemainVisible)
                clearInterval(this.blinkInterval);
        }
        else {
            this.cursorsIsVisible = true;
            this.element.innerHTML = this.element.innerHTML.slice(0, -1);
            this.element.innerHTML += '|';
        }
    };
    TypeWriter.prototype.typeWrite = function () {
        if (this.index <= this.text.length) {
            if (this.text.charAt(this.index - 1) == '\\') {
                this.insertString('<br/>');
                setTimeout(this.typeWrite.bind(this), Math.random() * this.speed / 10 + this.speed + this.newLineDelay);
            }
            else {
                this.insertString(this.text.charAt(this.index - 1));
                setTimeout(this.typeWrite.bind(this), Math.random() * this.speed / 10 + this.speed);
            }
            this.index += 1;
        }
        else if (this.index > this.text.length) {
            this.finished = true;
            this.callback();
        }
    };
    TypeWriter.prototype.insertString = function (str) {
        var last = '';
        if (this.cursorsIsVisible) {
            last = '|';
        }
        else {
            last = ' ';
        }
        var before = this.element.innerHTML.slice(0, -1);
        this.element.innerHTML = before + str + last;
    };
    return TypeWriter;
}());
exports.TypeWriter = TypeWriter;
