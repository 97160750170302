"use strict";
var blurElements = document.querySelectorAll('.blur');
var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting)
            entry.target.classList.add('blur-in');
        else
            entry.target.classList.remove('blur-in');
    });
});
blurElements.forEach(function (el) { return observer.observe(el); });
