"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Model = void 0;
var THREE = __importStar(require("three"));
var BufferGeometryUtils = __importStar(require("three/examples/jsm/utils/BufferGeometryUtils.js"));
var OBJLoader_1 = require("three/examples/jsm/loaders/OBJLoader");
var GLTFLoader_1 = require("three/examples/jsm/loaders/GLTFLoader");
require("../assets/objects/dog.obj");
var Model = /** @class */ (function () {
    function Model(fileName, scale, scene, isPoints, rotateX, rotateY, rotateZ) {
        if (scale === void 0) { scale = 1; }
        if (isPoints === void 0) { isPoints = true; }
        var _this = this;
        this.fileName = fileName;
        this.scale = scale;
        this.scene = scene;
        this.isPoints = isPoints;
        this.rotateX = rotateX;
        this.rotateY = rotateY;
        this.rotateZ = rotateZ;
        this.mesh = new THREE.Mesh();
        this.points = new THREE.Points();
        this.minHue = 0.6;
        this.hue = this.minHue;
        this.maxHue = 0.9;
        this.colorDirection = 1;
        this.saturation = 0.5;
        this.lightness = 0.1;
        this.color = new THREE.Color().setHSL(this.hue, this.saturation, this.lightness);
        this.material = new THREE.PointsMaterial({ color: this.color, blending: THREE.AdditiveBlending, size: 1, sizeAttenuation: false });
        this.solidMaterial = new THREE.MeshBasicMaterial({ color: this.color, blending: THREE.AdditiveBlending });
        this.loadingText = document.getElementById('percent-loaded-label');
        var gltfLoader = new GLTFLoader_1.GLTFLoader();
        var fileExtension = this.fileName.slice(this.fileName.indexOf('.') + 1);
        if (fileExtension == 'obj') {
            var objloader = new OBJLoader_1.OBJLoader();
            objloader.load('../assets/objects/' + this.fileName, this.addObj.bind(this), function (xhr) {
                _this.loading(xhr.loaded / xhr.total);
            }, function (error) {
                console.log(error);
            });
        }
        else if (fileExtension == 'gltf') {
            gltfLoader.load(this.fileName, this.addGLTF.bind(this), function (xhr) {
                _this.loading(xhr.loaded / xhr.total);
            }, function (error) {
                console.log(error);
            });
        }
        else {
            throw new Error("File extension not found: " + fileExtension);
        }
    }
    Model.prototype.update = function (delta) {
        if (this.hue > this.maxHue) {
            this.colorDirection = -1;
        }
        if (this.hue < this.minHue) {
            this.colorDirection = 1;
        }
        this.hue += delta / 10 * this.colorDirection;
        this.color.setHSL(this.hue, this.saturation, this.lightness);
        if (this.isPoints) {
            this.points.material = new THREE.PointsMaterial({ color: this.color, blending: THREE.AdditiveBlending, size: 1, sizeAttenuation: false });
        }
        else {
            this.mesh.material = new THREE.MeshBasicMaterial({ color: this.color, blending: THREE.AdditiveBlending });
        }
    };
    Model.prototype.loading = function (percentage) {
        if (this.loadingText == undefined)
            return;
        if (percentage == 1)
            this.loadingText.style.display = 'none';
        this.loadingText.innerHTML = 'Loading model: ' + Math.trunc(percentage * 100) + '%';
    };
    Model.prototype.addGLTF = function (gltf) {
        this.addObj(gltf.scene);
    };
    Model.prototype.addObj = function (obj) {
        var geometries = [];
        obj.traverse(function (child) {
            var mesh = child;
            if (!mesh.isMesh)
                return;
            var geom = mesh.geometry;
            geometries.push(geom);
        });
        var geom = BufferGeometryUtils.mergeBufferGeometries(geometries);
        geom.computeBoundingBox();
        if (geom.boundingBox == null) {
            throw new Error("Could not calculate bounding box");
        }
        var box = geom.boundingBox.max;
        var maxBox = Math.max(box.x, box.y, box.z);
        geom.center();
        geom.rotateX(this.rotateX);
        geom.rotateY(this.rotateY);
        geom.rotateZ(this.rotateZ);
        geom.scale(this.scale / maxBox, this.scale / maxBox, this.scale / maxBox);
        if (this.isPoints) {
            this.points = new THREE.Points(geom, this.material);
            this.scene.add(this.points);
            return;
        }
        else {
            this.mesh = new THREE.Mesh(geom, this.solidMaterial);
            this.scene.add(this.mesh);
        }
    };
    return Model;
}());
exports.Model = Model;
