"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.start = void 0;
var THREE = __importStar(require("three"));
var OrbitControls_1 = require("three/examples/jsm/controls/OrbitControls");
var model_1 = require("src/graphics/model");
var modelCanvas = document.getElementById('model-canvas');
var modelRenderer = new THREE.WebGLRenderer({ canvas: modelCanvas, alpha: true });
var ratio = setUpRenderer(modelRenderer);
var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(75, ratio, 0.1, 1000);
var controls = new OrbitControls_1.OrbitControls(camera, modelRenderer.domElement /*document.getElementById('main') as HTMLElement*/);
if (scene == null) {
    throw new Error();
}
controls.autoRotate = true;
controls.enableDamping = true;
controls.maxDistance = 2.5;
controls.minDistance = 1;
controls.enableZoom = false;
camera.position.z = 10;
camera.lookAt(new THREE.Vector3(0, 0, 0));
var model = new model_1.Model('dog.obj', 1.7, scene, true, Math.PI / 2, -Math.PI, Math.PI);
var clock = new THREE.Clock(true);
function animate() {
    var delta = clock.getDelta();
    model.update(delta);
    controls.update();
    modelRenderer.clear();
    modelRenderer.render(scene, camera);
    requestAnimationFrame(animate);
}
function onResize() {
    var ratio = setUpRenderer(modelRenderer);
    camera.aspect = ratio;
    camera.updateProjectionMatrix();
}
window.addEventListener('resize', onResize, false);
function setUpRenderer(renderer) {
    renderer.autoClear = false;
    var width = document.body.clientWidth;
    renderer.setSize(width, window.innerHeight);
    return width / window.innerHeight;
}
function start() {
    animate();
}
exports.start = start;
