"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollTracker = void 0;
var ScrollTracker = /** @class */ (function () {
    function ScrollTracker() {
        this.targetScroll = 0;
        this.startScroll = 0;
        this.duration = 500;
        this.startTime = 0;
    }
    Object.defineProperty(ScrollTracker.prototype, "currentScroll", {
        get: function () {
            return document.documentElement.scrollTop || document.body.scrollTop;
        },
        enumerable: false,
        configurable: true
    });
    ScrollTracker.prototype.stop = function () {
        this.startScroll = this.currentScroll;
        this.targetScroll = this.currentScroll;
    };
    ScrollTracker.prototype.absoluteScrollBy = function (y) {
        window.scrollBy(0, y);
    };
    ScrollTracker.prototype.absoluteScrollTo = function (y) {
        window.scrollBy(0, y - this.currentScroll);
    };
    ScrollTracker.prototype.scroll = function () {
        var frac = (Date.now() - this.startTime) / (this.duration);
        if (frac >= 1) {
            this.absoluteScrollTo(this.targetScroll);
            return;
        }
        this.absoluteScrollTo(this.easeInOutCubic(frac) * (this.targetScroll - this.startScroll) + this.startScroll);
        requestAnimationFrame(this.scroll.bind(this));
    };
    ScrollTracker.prototype.scrollBy = function (y) {
        this.targetScroll = this.currentScroll + y;
        this.startTime = Date.now();
        this.startScroll = this.currentScroll;
        this.scroll();
    };
    ScrollTracker.prototype.easeInOutCubic = function (x) {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    };
    return ScrollTracker;
}());
exports.ScrollTracker = ScrollTracker;
